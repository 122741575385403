<template>
    <div class="cbox">
        <div style="display: flex; height:100%; align-items: center; justify-content: center;">
            <div style="display: flex; height:100%;overflow: auto; ">
                <div style="padding:0 20px">
                    <div class="header" style="background-color: greenyellow;">线上数据库表</div>
                    <div :class="['tableitem', table.differ ? 'differ' : '', table.name == ckdTable ? 'ckd' : '']"
                        @click="checkTable(table.name)" v-for="(table, idx) in tablePro" :key="idx">{{ table.name }}</div>

                        <div style="height:100px"></div>
                </div>
                <div style="padding:0 20px">
                    <div class="header">测试数据库表</div>
                    <div :class="['tableitem', table.differ ? 'differ' : '', table.name == ckdTable ? 'ckd' : '']"
                        @click="checkTable(table.name)" v-for="(table, idx) in tableDev" :key="idx">{{ table.name }}</div>
                        <div style="height:100px"></div>
                </div>
            </div>

            <div style="display: flex; height:100%;overflow: auto; ">

                <div style="height: 100%; overflow: auto; padding:0 20px;width:300px">
                    <div class="header" style="background-color: greenyellow;">线上【{{ ckdTable }}】</div>
                    <div v-for="(table, idx) in tablePro" v-if="ckdTable == table.name" :key="idx">

                        <div :title="JSON.stringify(col)" :class="['tableitem', col.differ ? 'differ' : '']" v-for="(col, i) in table.columns"
                            :key="i">
                            【{{ col.Field }}】【{{ col.Type }}】


                        </div>
                    </div>
                </div>
                <div style="height: 100%; overflow: auto; padding:0 20px;width:300px">
                    <div class="header">测试【{{ ckdTable }}】</div>
                    <div v-for="(table, idx) in tableDev" v-if="ckdTable == table.name" :key="idx">

                        <div :title="JSON.stringify(col)" :class="['tableitem', col.differ ? 'differ' : '']" v-for="(col, i) in table.columns"
                            :key="i">
                            【{{ col.Field }}】【{{ col.Type }}】


                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
  
<script>
export default {
    data() {
        return {
            tablePro: [],
            tableDev: [],
            ckdTable: ""

        }
    },
    mounted() {
        this.getList();

    },
    methods: {
        getList() {
            this.$http.post("/api/dbcheck_gettables").then(res => {
                this.tablePro = res.data.tablePro

                this.tableDev = res.data.tableDev
            })
        },
        checkTable(name) {
            this.ckdTable = name
        }
    }
}

</script>
  
<style scoped>
.tableitem {
    padding: 5px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.differ {
    color: orangered;
}

.ckd {
    background-color: deepskyblue;
}

.header {
    font-size: 16px;
    background-color: cornflowerblue;
    color: #fff;
    font-weight: bold;
    padding: 10px
}
</style>
  